body {
    padding-top: 2.8rem;
    margin: 0;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #e1e2e1 !important;
    color: #313131 !important;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.cursorHand {
    cursor: pointer;
}
.MuiInputBase-input {
    color: #313131 !important;
}
.MuiSelect-icon {
    color: #313131 !important;
}
.MuiSvgIcon-colorPrimary {
    color: #bd2640 !important;
}
.checkBoxTabel > span .MuiSvgIcon-root {
    color: #bd2640 !important;
}
.basic-navbar-nav {
    color: #313131 !important;
}
input {
    color: #313131 !important;
}
.MuiCheckbox-root {
    color: #313131 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
    color: #ff7500 !important;
}
textarea {
    color: #313131 !important;
}
.MuiInput-underline:before {
    border-bottom: 1px solid #313131 !important;
}
.MuiInput-underline:after {
    border-bottom: 1px solid #ff7500 !important;
}
.MuiButton-containedPrimary, .MuiButton-containedPrimary:hover {
    background-color: #bd2640 !important;
    color: #fff !important;
}
fieldset > legend {
    color: #313131 !important;
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    -webkit-text-fill-color: #313131;
    -webkit-box-shadow: 0 0 0px 1000px #e1e2e1 inset;
    transition: background-color 5000s ease-in-out 0s;
}
.checkBox {
    color: #313131 !important;
}
.MuiOutlinedInput-notchedOutline {
    border-color: #313131 !important;
}
label {
    color: #313131 !important;
}
.paper {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.avatar {
    margin: 8px;
    background-color: #bd2640 !important;
}
.form {
    width: 100%;
    margin-top: 8px;
}
.submit {
    margin: 24px 0 16px !important;
    background-color: #bd2640 !important;
    color: #fff !important;
}
.btnUbahPass {
    margin: 24px 0 16px !important;
    background-color: #4682B4 !important;
    color: #fff !important;
}
a {
    color: #e6e6e6 !important;
}
.spinner {
    position: fixed;
    left: 50%;
    top: 50%;
    margin: 10px 0 10px;
    z-index: 9999;
}
.bg-navbar {
    background-color: #040716;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #30323a !important;
    border-color: #dee2e6 #dee2e6 #fff !important;
}
.nav-tabs {
    border-bottom: 1px solid #a59e9e !important;
}
.modal-content {
    background-color: #384161 !important;
}
.MuiSwitch-colorSecondary.Mui-checked {
    color: #bd2640 !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #bd2640 !important;
}
.btnTable {
    padding: 11px 15px;
}
.navbar-light .navbar-toggler-icon {
    background-image: url("/navbar.png");
}
.MuiButton-outlinedPrimary {
    color: #bd2640 !important;
    border: 1px solid #ec7510 !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #bd2640 !important;
    border-color: #dee2e6 #dee2e6 #a59e9e !important;
    color: #fff !important;
}
.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}
.mg-top64 {
    margin-top: 64px;
}
.hrefOutlet {
    color: #f2f2f2 !important;
    font-size: 1em;
    margin: 0 auto 0;
    text-decoration: underline;
}
.MuiFormLabel-root {
    color: #313131 !important;
}
.garis-bawah {
    border-bottom: 1px solid #313131;
    padding: 5px 0px;
}
.navbar {
    padding: 0px 15px !important;
    margin: -4px 0px !important;
}
.MuiTablePagination-actions {
    margin-left: -50px !important;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: transparent;
}
.MuiDialog-paper {
    background-color: #e1e2e1 !important;
    margin: 18px !important;
}
.MuiDialog-paperWidthSm {
    width: 90% !important;
}
.MuiButton-textPrimary {
    color: #bd2640 !important;
}
.colorWhite {
    color: #000 !important;
}
.colorBlack {
    color: #000 !important;
}
@media (min-width: 768px) {
    .MuiTablePagination-caption {
        padding-right: 35px !important;
   }
}
.skeleton-line > span > span.css-19bon8n {
    height: 1px;
}
.colorRed {
    color: #bd2640 !important;
}
.sizeIconHapusOutlet {
    font-size: 2.2em !important;
}
.marginLeft20 {
    margin-left: 20px !important;
}
.tabelWhite > th, .tabelWhite > td {
    color: #313131 !important;
}
.btn-primary {
    background-color: #bd2640 !important;
    border-color: #bd2640 !important;
}
.btnAddManual {
    background-color: #006db3 !important;
    margin-right: 10 !important;
    color: #fff !important;
    width: 100% !important;
}
.btnImportExcel {
    background-color: #9a0 !important;
    margin-right: 10 !important;
    color: #fff !important;
    width: 100% !important;
}
.btnImportExcelCatatan {
    background-color: #005b4f !important;
    margin-right: 10 !important;
    color: #fff !important;
    width: 100% !important;
}
.btnAddMultiple {
    background-color: #00867d !important;
    margin-right: 10 !important;
    color: #fff !important;
    width: 100% !important;
}
.MuiTypography-body2 {
    color: #313131 !important;
}
.MuiPickersSlideTransition-transitionContainer > * {
    color: #030303 !important;
}
.MuiPickersCalendarHeader-dayLabel {
    color: #030303 !important;
}
.MuiPickersToolbar-toolbar {
    background-color: #bd2640 !important;
}
.MuiTypography-subtitle1 {
    color: #fff !important;
}
.MuiPickersCalendarHeader-iconButton {
    background-color: transparent !important;
}
.MuiPickersDay-daySelected {
    background-color: #f5aaae !important;
    color: #fff !important;
}
.MuiPickersModal-dialogRoot {
    min-width: 310px !important;
}
.tangan {
    cursor: pointer;
}
.paadingRightLeft15 {
    padding: 0 15px;
}
.MuiAppBar-colorPrimary {
    position: fixed !important;
    color: #fff;
    background-color: #bd2640 !important;
   /* background-color: #c7783f !important;
    */
}
.DialogFont {
    color: #000 !important;
}
.tabelForm > div .MuiFormControl-root > input .MuiInputBase-input {
    color: #000 !important;
}
.marginTop15 {
    margin-top: 15px !important;
}
.bodyDialog {
    margin-top: 65px;
}
.MuiDialog-paperFullScreen {
    min-width: 320px !important;
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.MuiTab-textColorPrimary.Mui-selected {
    color: #fff !important;
}
.MuiTab-textColorPrimary {
    color: #fff !important;
}
.MuiTabs-centered {
   /* border-bottom: 2px solid #ded6d6;
    */
    background-color: #bd2640;
}
.MuiTabs-indicator {
    background-color: #fff !important;
}
button:focus {
    outline: 0px auto !important;
}
.MuiTabs-root {
    margin-top: -9px !important;
}
.bgTabel {
    background-color: #bd2640;
}
.MuiDialogActions-root {
    border-top: 1px solid white;
}
.MuiDialogTitle-root {
    border-bottom: 1px solid white;
}
.bgContent-orderan {
    background-color: #b7b7b7;
    padding: 10px;
}
.ubahTanggal {
    font-size: 14px;
    color: #3856f3;
    cursor: pointer;
    font-weight: bold;
    font-style: oblique;
}
.bgRowCancel {
    background-color: #fb4646;
}
.bgRowCancel:hover {
    background-color: #ec2828 !important;
}
.MuiDialog-paperWidthSm {
    min-width: 310px !important;
}
.font12 {
    font-size: 12px !important;
}
.navbar {
    padding: 10px !important;
}
.bg-dark {
    background-color: #bd2640 !important;
}
.btnBack {
    color: #313131 !important;
    cursor: pointer;
}
.MuiButton-containedPrimary, .MuiButton-containedPrimary:hover {
    background-color: #bd2640 !important;
    color: #fff !important;
}
.border-bottom {
    border-bottom: 1px solid #929292 !important;
}
.pilihOutlet {
    margin-top: -25px;
    color: #bd2640;
}
.bgPilihOutlet {
    background-color: #bd2640;
}
.pilihOutlet {
    margin-top: -25px;
    color: #bd2640;
}
.colorLogin {
    color: #bd2640 !important;
}
.manajemenOutlet {
    margin-top: 15px;
    color: #bd2640;
}
.hrefColor {
    color: #bd2640 !important;
}
.MuiTableCell-root {
    border-bottom: 1px solid #5d5252 !important;
}
.btnRiwayatTrans {
    background-color: #bd2640 !important;
    margin-right: 10;
    color: #fff !important;
}
/* Laporan */
.bg-totPenerimaan {
    background-color: #00701a !important;
    color: #fff;
}
.bg-todTrans {
    background-color: #524c00 !important;
    color: #fff;
}
.bg-todCabang {
    background-color: #005662 !important;
    color: #fff;
}
.bg-pembayaran {
    background-color: #003d33 !important;
    color: #fff;
}
.bg-laporan {
    background-color: #f3f3f3 !important;
    color: #333;
}
.judul-kotak {
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 0px 0 0;
    margin-bottom: 0;
    padding: 14px 15px 7px;
    min-height: 48px;
}
.box {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
}
.right {
    float: right !important;
}
.label {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 55%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
}
.box-content {
    padding: 15px 20px 20px 20px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}
.isiBayar {
    font-size: 20px;
    font-weight: bold;
}
.colorIconBranch {
    color: #333 !important;
}
.box-report {
    padding: 15px 20px 20px 20px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
    height: 110px;
}
.bg-report1 {
    background-color: #324851 !important;
    color: #fff;
}
.bg-report2 {
    background-color: #86ac41 !important;
    color: #fff;
}
.bg-report3 {
    background-color: #34675c !important;
    color: #fff;
}
.prev, .next {
    background-color: #bd2640;
}
.active-btnGroup {
    background-color: #ef3627 !important;
}
.active-btnGroup:hover {
    background-color: #ef3627 !important;
}
.MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
    border-right: 1px solid #f99b98 !important;
}
.MuiIconButton-label > .MuiSvgIcon-root {
    color: #383737 !important;
}
/* baru */
th {
    text-align: none !important;
}
.borderMenu {
    border: 3px solid #bd2640;
    padding: 40px 15px 0px;
    margin: 10px 1px;
}
.menuName {
    margin-top: -60px;
    text-align: center;
}
.menuName > span {
    font-size: 24px;
    color: #bd2640;
    font-weight: bold;
    background-color: #e1e2e1;
    padding: 0 7px;
}
.btnColor, .hrefColor {
    color: #bd2640 !important;
}
.linkBlack {
    color: #000 !important;
}
.bgHeaderTabel {
    background-color: #bd2640 !important;
    color: #fff !important;
}
.MuiPickerDTTabs-tabs {
    background-color: #bd2640 !important;
}
.MuiPickersClock-clock {
    background-color: #838383 !important;
}
.MuiPickersClockPointer-pointer {
    background-color: #bd2640 !important;
}
.MuiPickersClock-pin {
    background-color: #bd2640 !important;
}
.MuiPickersClockNumber-clockNumber {
    color: rgba(249, 245, 245, 0.87) !important;
}
.MuiPickersClockPointer-thumb {
    border: 14px solid #bd2640 !important;
}
.table-hover tbody tr:hover {
    background-color: #ccc !important;
    color: #131212 !important;
}
td > .MuiSvgIcon-root {
    color: #bd2640 !important;
}
.colorVoid {
    color: #000;
}
.bgModal {
    background-color: #e1e2e1 !important;
}
.boderSession {
    border-top: 3px solid #231e1e !important;
}
.col-md-1, .col-2 > .MuiSvgIcon-root {
    color: #ff1300 !important;
}
button > .MuiIconButton-label > .MuiSvgIcon-root {
    color: #252525 !important;
}
.MuiGrid-root > button > .MuiIconButton-label > .MuiSvgIcon-root {
    color: #fff !important;
}
/* new */
.buttonExportExcel {
    color: #fff;
    background-color: #bd2640;
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
}
.bgHeaderTabel > th .MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: #fff;
}
.bgHeaderTabel > th .MuiTableSortLabel-root:hover {
    color: #fff;
}
.menuLaporan > .navbar {
    background-color: #bd2640;
}
.namaLaporan {
    color: black;
    margin-top: 10px;
    text-align: center;
}
@media (max-width: 767px) {
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: #3e3737 !important;
        background-color: #f1f0f0;
   }
    .nav-item > .nav-link {
        color: #f1f0f0 !important;
   }
}
@media (min-width: 768px) {
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: #fff !important;
        background-color: #bd2640;
   }
    .nav-item > .nav-link {
        color: #000 !important;
   }
}
.MuiListItemIcon-root > .MuiSvgIcon-root {
    color: #3a3636 !important;
}
.MuiToolbar-root > button > .MuiIconButton-label > .MuiSvgIcon-root {
    color: #fff !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
    background-color: #e1e2e1 !important;
    padding-top: 10px !important;
}
.MuiInputBase-root > .MuiSvgIcon-root {
    color: #2c2a2a !important;
}
.MuiIconButton-label > div > .MuiSvgIcon-root {
    color: #2c2a2a !important;
}
.MuiTablePagination-actions {
    margin-left: -10px !important;
}
.MuiTablePagination-actions > .MuiIconButton-root {
    padding: 5px !important;
}
.bgButton {
    background-color: #2057c7 !important;
    border: 2px solid #99aed8 !important;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(251, 0, 0, 0.19);
}
nav > a {
    color: #000 !important;
}
.MuiButton-label > .MuiSvgIcon-root {
    color: #fff !important;
}
.MuiFormControl-root {
    padding-top: 5px !important;
}
.ubahJumlah {
    font-size: 13px;
    color: #074ff1;
    font-family: sans-serif;
}
.ketTransaksi {
    margin-bottom: 5px !important;
}
.btnRiwayatTrans > .MuiSvgIcon-root {
    color: #fff !important;
}
.formInputJamaah {
    border: 3px solid #bd2640;
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 0px;
}
.formPaketDetail {
    font-size: 14px !important;
}
.MuiButton-contained.Mui-disabled {
    background-color: #bd2640 !important;
}
.gantiPassword {
    color: #bd2640;
    font-size: 15px;
}
.textColorprimary {
    color: #bd2640;
}
.textDeleteJamaah > .MuiSvgIcon-root {
    color: #f00 !important;
}
.btnPesanPaket {
    position: fixed !important;
    bottom: 0 !important;
    width: 90% !important;
    margin-top: 60px !important;
}
.MuiBottomNavigationAction-root.Mui-selected {
    color: #bd2640 !important;
}
.MuiFab-primary {
    color: #fff;
    background-color: #bd2640 !important;
}
.btn3 {
    background-color: #61651d !important;
    color: #fff !important;
}
.img-btn {
    max-height: 20px;
}
.btn-card {
    background-color: #bd2640;
    border-radius: 11px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
}
.border-btm {
    border-bottom: 1px solid #9c9e9c;
    margin: 0px 20px;
}
.cardOrderPay {
    padding: 15px 10px;
    display: flex;
    width: 100%;
}
.headCart {
    color: #bd2640;
    cursor: pointer;
    font-size: 16px;
    text-decoration: underline;
}
.width100 {
    width: 100%;
}
.MuiButton-label > .MuiSvgIcon-root {
    color: #403c3c !important;
}
.pd10TB {
    padding: 10px 0px;
}
.MuiButton-contained.Mui-disabled {
    background-color: #d67c8c !important;
}
.table-akun{
    margin-top: 20px !important;
    font-size: 18px !important;
    width: 100%;
}
.table-akun th{
    width: 350px !important;
}

.table-akun table {
    width: 100% !important; 
    max-width: 100% !important;
    border-collapse: collapse;
}
.table-akun th, td {
    padding: 8px; 
    text-align: left; 
}
.tbakun-right{
    text-align: right !important;
}
.card {
    max-width: 30em;
    flex-direction: row;
    background-color: #696969;  
    border: 0;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
    margin:0.3em auto;
  }
  .card.dark {
    color: #fff;
  }
  .card.card.bg-light-subtle .card-title {
    color: dimgrey;
  }
  
  .card img {
    max-width: 25%;
    margin: auto;
    padding: 0.5em;
    border-radius: 0.7em;
  }
  .card-body {
    display: flex;
    justify-content: space-between;
  }
  .text-section {
    max-width: 60%;
  }
  .cta-section {
    max-width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
  .cta-section .btn {
    padding: 0.3em 0.5em;
    /* color: #696969; */
  }
  .card.bg-light-subtle .cta-section .btn {
    background-color: #898989;
    border-color: #898989;
  }
  @media screen and (max-width: 475px) {
    .card {
      font-size: 0.9em;
    }
  }
  
  .mgBottom{
    margin-bottom: 20px !important;
  }
  .MuiFormControl-marginNormal {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .marginMenuItem{
    margin: 7px !important
  }