.items-img {
    text-align: center;
    width: auto;
    height: auto;
    margin-bottom: 1%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .itemsnotfound-img {
    text-align: center;
    width: 220px;
    height: 100px;
    margin-bottom: 1%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .items-img img {
    width: 100%;
    height: 15vh;
    object-fit: contain;
  }
  
  .items-img .file {
    position: relative;
    overflow: hidden;
    margin-top: -5%;
    width: 100%;
    border: none;
    border-radius: 0;
    font-size: 15px;
    background: #009bde;
    object-fit: cover;
    color: #fff;
    padding: 1px;
  }
  
  .items-img .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
  }
  
  .showgambar {
    width: 450px;
    height: 400px;
    position: relative;
    overflow: hidden;
    display: block;
  }
  
  .showgambar img {
    position: absolute;
    width: 100%;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .itemscard {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 40%;
    border-radius: 5px;
  }
  
  .itemscard:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  
  .itemscard .img {
    border-radius: 5px 5px 0 0;
  }
  
  .itemscard .container {
    padding: 2px 16px;
  }
  
  .fixed-top {
    position: sticky;
    top: 55px;
    z-index: 1;
  }
  .scrolled {
    background-color: white;
  }
  
  .file-text-item {
    font-size: 10px;
    text-align: left;
    white-space: nowrap;
  }
  
  .file-text-stok {
    font-size: 12px;
  }
  
  .file-text-harga {
    font-size: 12px;
  }
  
  .file-text-merk {
    font-size: 11px;
    padding-top: 2px;
    text-align: center;
  }
  
  .titleFilter {
    font-size: 12px;
    font-weight: bold;
  }
  
  @media only screen and (max-width: 600px) {
    .csSizeBtn {
      font-size: 7px !important;
    }
  
    .file-text-item {
      font-size: 7px;
      text-align: left;
    }
  
    .file-text-deskripsi {
      font-size: 7px;
      text-align: center;
      white-space: nowrap;
    }
  
    .file-text-merk {
      font-size: 8px;
      text-align: center;
    }
  
    .gridStokMobile .col-4 {
      padding-left: 3px;
      padding-right: 1px;
      padding-bottom: 1px;
      width: calc(33.3333% - 1px);
      /* 3 kolom per baris */
    }
  
    .margin-top-mobile {
      margin-top: 7px !important;
    }
  
    .file-text-stok {
      font-size: 8px;
    }
  
    .file-text-harga {
      font-size: 8px;
    }
  
    /* Untuk menghapus padding kanan dari kolom terakhir pada setiap baris */
    .gridStokMobile .col-4:nth-child(3n) {
      padding-right: 0;
    }
  }
  
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    .file-text-item {
      font-size: 11px;
    }
  }
  
  @media only screen and (min-width: 1025px) {
    .file-text-item {
      font-size: 12px;
    }
  }
  .MuiButton-label > .MuiSvgIcon-root {
    color: #fff !important;
  }