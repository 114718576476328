.prev,
.next {
    cursor: pointer;
    position: absolute;

    width: auto;
    margin-top: 25px;
    color: #fff !important;
    font-weight: bold;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

@media (min-width: 320px) {

    .prev,
    .next {
        margin-top: 155px;
    }

    .next-right {
        margin-left: 250px
    }

    .MuiButton-containedPrimary {
        font-size: 12px !important;
    }

    .mobile {
        border-bottom: 1px solid #929292 !important;
    }


    .contentManual {
        padding-left: 25px;
    }

}

@media (min-width: 360px) {
    .next-right {
        margin-left: 290px
    }


}

@media (min-width: 375px) {
    .next-right {
        margin-left: 310px
    }

    .MuiButton-containedPrimary {
        font-size: 14px !important;
    }
}

@media (max-width: 767px) {
    .next-right {
        right: 0;
        margin-right: 24px;
    }
}

@media (min-width: 768px) {

    .prev,
    .next {
        margin-top: 45px;
    }

    .next-right {
        margin-left: 475px
    }

    .totLaporan {
        padding-top: 10px;
        font-size: 16px;
    }

    .itemName {
        min-width: 200px;
    }

    .contentManual {
        padding: 5px 30px;
    }

    main {
        margin-top: 10px;
    }
    /* .img-slide{
        max-height: 360px !important;
    } */
}
@media (min-width: 1024px) {
    .img-slide{
        max-height: 500px !important;
    }
}

@media (min-width: 992px) {

    .prev,
    .next {
        /* position: fixed; */
        margin-top: 45px;
    }

    .next-right {
        margin-left: 578px
    }

    .totLaporan {
        font-size: 19px;
    }
}

@media (min-width: 1200px) {

    .prev,
    .next {
        /* position: fixed; */
        margin-top: 45px;
    }

    .next-right {
        margin-left: 695px
    }
}

.activeList {
    background-color: #bfbcbc;
}



.grafikMax>canvas {
    max-height: 400px !important;
}

.marginGrafik {
    margin-top: 20px;
}

.tabelCenter>th {
    text-align: center;
}

.deleteDialog {
    margin-top: 15px;
    cursor: pointer;
}

.bgRowCancel>td {
    color: #fff !important;
}

.bodySessionDetail {
    display: block;
    overflow: auto;
    width: 100%;
}

.login {
    background: #fbfbfb;
    border-radius: 8px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
    padding: 13px;
    margin: 0px -26px;
}

.MuiListItemText-root>.MuiListItemText-primary {
    color: #000;
}

.iconLogout>.MuiListItemIcon-root>.MuiSvgIcon-root,
.iconLogout>.MuiListItemText-root>.MuiListItemText-primary {
    color: #ff0000 !important;
}

.table-responsive-manual {
    width: 90%;
}

.MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.iconMenu {
    height: 25px;
}

.paddingBottom5 {
    padding-bottom: 5px;
}

.headerPaket {
    font-size: 18px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
    letter-spacing: 0.0075em !important;
    text-align: center;
    color: #3e0500
}


.textDetailMenu {
    font-size: 12px !important;
}

.form>label {
    margin-bottom: 0px !important;
}

.MuiTypography-body1 {
    font-size: 15px;
}

.MuiFormControl-root {
    padding: 10px 0 !important;
}

.pdTop10 {
    padding-top: 10px;
}

.formLabel>.MuiFormGroup-root {
    margin-bottom: 10px;
}

.textDeleteJamaah {
    color: #f00;
    cursor: pointer;
    margin-left: 1px;
    margin-right: 1px;
}

.MuiListItem-gutters {
    padding-left: 24px !important;
    padding-right: 16px !important;
}

.textContactUs {
    font-size: 15px !important;
}

.img-maxTabel {
    max-height: 134px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}
.ketHeader{
    font-size: 13px;
    font-weight: 500;
}
.itemGet{
    margin-left: -23px;;
}
.MuiCardContent-root > a > p {
    color: #000 !important;
}
.colorRed{
    color: #f00 !important;
}
.content-full{
    position: relative;
    width: 100%;
}
.mgTopMin5{
    margin-top: -5px!important;
}
.mgTop5{
    margin-top: 5px!important;
}
.pdImage{
    padding: 5px;
}
.mgLRMin30{
    margin-left: -30px!important;
    margin-right: -30px!important;
}
.bgB60{
    margin-bottom: 60px;
}
.img-outlet{
    height: 200px;
}
.imgOutlet{
    margin: 10px 0;
}
.flex{
    display: flex;
}
.imgMenu{
    max-height: 200px;
    margin: 5px 0px;
}